<template>
  <header class="home-nav">
    <div class="center-block">
      <div class="left-link">
        <div class="left-link-logo" @click="goHome">
          <img :src="'/img/nav/logo-black.png'|cdn" alt="logo" class="nav-logo">
          <span class="nav-name">同袍科技</span>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "tp-nav",
  methods:{
    goHome(){
      const routeName = this.$route.name
      if(routeName !='home-v1'){
        this.$router.push({name:'home-v1'})
      }
      // console.log(this.$route.name)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../style/var";

.home-nav {
  background-color: #fff;
  width: 100%;
  height: 98px;
}

.center-block {
  @include center();
  height: inherit;
}

.left-link {
  height: inherit;

  .left-link-logo {
    cursor: pointer;
    display: inline-block;
    padding-right: 10px;
  }
}

.nav-logo {
  display: inline-block;
  vertical-align: middle;
  width: 45px;
  height: 55px;
  margin-top: 22px;
}

.nav-name {
  display: inline-block;
  vertical-align: middle;
  margin-top: 22px;
  margin-left: 12px;
  font-size: 24px;
  font-weight: 500;
  color: #313131;
  line-height: 1;
}
</style>
