<template>
	<div class="features">
		<!--					1-->
		<div class="f-item">
			<div class="img-wrap">
				<img src="../../assets/icon/example-pi/icon-1.png" width="115" alt=""/>
			</div>
			<div class="text">全景相机直播 支持VR<br>实时回传现场画面</div>
		</div>
		<!--					2-->
		<div class="f-item">
			<div class="img-wrap">
				<img src="../../assets/icon/example-pi/icon-2.png" width="109" alt=""/>
			</div>
			<div class="text">PC端/手机端全景播放<br>无死角拖动查看
			</div>
		</div>
		<!--					3-->
		<div class="f-item">
			<div class="img-wrap">
				<img src="../../assets/icon/example-pi/icon-3.png" width="102" alt=""/>
			</div>
			<div class="text">多端即时通讯<br>音视频会议远程指挥</div>
		</div>
		<!--					4-->
		<div class="f-item">
			<div class="img-wrap">
				<img src="../../assets/icon/example-pi/icon-4.png" width="92" alt=""/>
			</div>
			<div class="text">后台管理模块<br>实时掌握项目进展、设备状况</div>
		</div>
		<!--					5-->
		<div class="f-item">
			<div class="img-wrap">
				<img src="../../assets/icon/example-pi/icon-5.png" width="86" alt=""/>
			</div>
			<div class="text">软硬件一体化解决方案<br>支持5G/8K</div>
		</div>
	</div>
</template>
<script>
    export default {
        name: "example-pi-features",
    }
</script>
<style lang="scss" scoped>
	@import "../../style/var";

	.features {
		@include center();
		display: flex;
		align-items: stretch;

		.f-item {
			flex: 1;
			text-align: center;

			.img-wrap {
				height: 150px;
				display: inline-block;

				img {
					display: block;
					margin: auto;
					margin-top: 75px;
					transform: translateY(-50%) scale(0.7);
				}
			}

			.text {
				height: 64px;
				font-size: 20px;
				font-weight: 400;
				color: #272727;
				line-height: 32px;
				margin: 0 auto;
			}
		}
	}
</style>
