<template>
  <div class="page-vr-city">
    <div class="vr-city" :style="`background-image:url(${bg1})`" @click="goDirectPage"></div>
    <div class="page-bottom">
      <div class="right-text-bg"></div>
      <div class="bottom-content-wrap">
        <img :src="'/img/example-vr-city/NUESCO.png'|cdn" alt="" class="left-img">
        <div class="right-content">
          <div class="subtitle">合作方</div>
          <div class="title">联合国教科文组织（UNESCO)</div>
          <div class="describe">我们与联合国教科文组织官方合作，使用 unity 开发 VR 虚拟城市，通过在城市中散步，和人物对话等方式，寓教于乐，以达到文化宣传的效果。</div>
          <div class="url"><img :src="'/img/example-vr-city/vr-city-arrow.png'|cdn" alt=""> <a href="https://milcity.unesco.tpstu.com">milcity.unesco.tpstu.com</a>  </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {scrollTo} from "@/utils"
export default {
  name: 'example-vr-city',
  data(){
    return{
      bg1:this.$cdn("/img/example-vr-city/vr-city.jpg")
    }
  },
  mounted() {
    document.title = `同袍科技-联合国VR城市`;
    scrollTo(0)
  },
  methods:{
    goDirectPage(){
      window.location.href = 'https://milcity.unesco.tpstu.com'
    }
  }

}
</script>
<style lang="scss" scoped>
@import "../style/var";

.page-vr-city {
  width: 100%;

  .vr-city {
    width: 100vw;
    height: (1050 / 1920) * 100vw;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  .page-bottom {
    width: 100%;
    position: relative;

    .right-text-bg {
      width: (1248 / 1920) * 100%;
      height: 385px;
      background: #FAF8F5;
      position: absolute;
      right: 0;
      top:14px;
    }
    .bottom-content-wrap{
      @include center;
      position: relative;
      .left-img{
        width: 553px;
        height: 379px;
        margin-top: 129px;
        margin-bottom: 33px;
        position: relative;
        z-index: 10;
      }
      .right-content{
        max-width: 693px;
        position: absolute;
        top:75px;
        right: 10px;
        .subtitle{
          font-size: 36px;
          font-weight: 400;
          color: #272727;
          line-height: 50px;
        }
        .title{
          font-size: 48px;
          font-weight: 600;
          color: #272727;
          line-height: 67px;
          font-family: "siyuanBold", sans-serif;
        }
        .describe{
          font-size: 24px;
          font-weight: 400;
          color: #272727;
          line-height: 32px;
          margin-top: 30px;
        }
        .url{
          font-size: 24px;
          font-weight: 400;
          line-height: 33px;
          margin-top: 27px;
          img{
            width: 59px;
            height: 7px;
            display: inline-block;
            line-height: 33px;
            vertical-align: middle;
            margin-right: 20px;
          }
          a{
            color: #917850;
            text-decoration : none
          }
        }
      }
    }
  }
}
</style>
