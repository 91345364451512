<template>
  <footer class="home-footer" :style="`background-image: url(${$cdn('/img/footer/footer-bg.jpg')})`">
    <div class="center-block">
      <!--			logo块-->
      <div class="a1">
        <img class="logo" :src="'/img/footer/logo-white.png'|cdn" alt="logo">
        <span class="logo-name">同袍科技</span>
      </div>
      <!--			联系方式-->
      <div class="a2 m-contact">
        <div class="l1">联系方式</div>
        <div class="l2">电话: 13070131998</div>
      </div>
      <!--			邮箱-->
      <div class="a3 m-contact">
        <div class="l1"></div>
        <div class="l2">邮箱： contact@tongpaostudio.com</div>
      </div>
      <!--			地址-->
      <div class="a4 m-contact">
        <div class="l1"></div>
        <div class="l2">地址：北京市朝阳区惠新西街16号</div>
      </div>
      <!--			二维码-->
      <div class="a5">
        <img class="qr-img" :src="'/img/footer/footer-qr.jpg'|cdn" alt="coolger"/>
        <div class="qr-text">扫一扫，联系我们</div>
      </div>
    </div>
    <div class="bottom-info">
      <a href="https://beian.miit.gov.cn/"
         target="_blank">辽ICP备17021872号-2</a> COPYRIGHT © 2016 – 2021 TONGPAOSTUDIO. ALL RIGHTS RESERVED 北京同袍科技有限公司 版权所有
    </div>
  </footer>
</template>
<script>
export default {
  name: "tp-footer"
}
</script>
<style lang="scss" scoped>
@import "../style/var";

.home-footer {
  background-color: #000;
  width: 100%;
  height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  float: left;
}

.center-block {
  @include center();
  height: 67px + 152px;
  margin-top: 52px;
  border-bottom: 1px solid #fff;
}

.a1 {
  display: inline-block;
  vertical-align: top;
  margin-top: 71px - 52px;

  .logo {
    display: inline-block;
    vertical-align: middle;
    width: 45px;
    height: 55px;
  }

  .logo-name {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    margin-left: 12px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
  }
}

.m-contact {
  display: inline-block;
  vertical-align: top;
  margin-top: 74px;
  margin-left: 36px;
  margin-right: 36px;

  .l1 {
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
    height: 28px;
    line-height: 28px;
  }

  .l2 {
    margin-top: 15px;
    color: #fff;
    font-size: 18px;
    line-height: 25px;
  }
}

.a2 {
  margin-left: 72px;
}

.a5 {
  display: block;
  float: right;

  .qr-img {
    display: block;
    width: 152px;
    height: 152px;
  }

  .qr-text {
    margin-top: 14px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 25px;
  }
}

.bottom-info {
  @include center();
  height: 25px;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 25px;
  margin-top: 24px;
  text-align: center;
  a {
    height: 25px;
    cursor: pointer;
    font-size: 18px;
    color: #caab78;
    line-height: 25px;
  }
}

</style>
