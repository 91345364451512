<template>
  <div class="page-h5">
    <div class="page-one" :style="`background-image:url(${bg1})`" ref="page1">
      <div class="page-bg-mask">
        <div class="page-content" :class="page1AniShow ? `show-content` : ``">
          <div class="left-content">
            <div class="main-title">雨中城</div>
            <div class="sub-title page-one-sub-title">——品牌H5</div>
            <div class="content-text page-one-content">
              如果有一座永远下雨的城市，会是什么样？<br />
              我们协助新世相和百度共同创建了一座虚拟的“一直下雨的城市”，在“雨声博物馆”中，你可以置身于虚拟全景中，欣赏来自世界各地的雨声和故事。
            </div>
            <div class="content-text">
              合作方：新世相 X 百度<br />
              项目功能：品牌宣传<br />
              技术特色：<span>多种陀螺仪交互，高性能 3D 全景场景交互。</span>
            </div>
            <div class="page-one-qrcode">
              <img :src="'/img/example-h5/page-one-qrcode.jpg' | cdn" alt="" />
              <span class="content-text">扫码体验</span>
            </div>
          </div>
          <div class="page-right-phones">
            <img
              :src="'/img/example-h5/page-one-phone-left.png' | cdn"
              alt=""
              class="phone"
            />
            <img
              :src="'/img/example-h5/page-one-phone-right.png' | cdn"
              alt=""
              class="phone phone-right"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="page-two" :style="`background-image:url(${bg2})`" ref="page2">
      <div class="page-bg-mask">
        <div class="page-content" :class="page2AniShow ? `show-content` : ``">
          <div class="page-left-phones">
            <img
              :src="'/img/example-h5/page-two-phone-left.png' | cdn"
              alt=""
              class="phone phone-left"
            />
            <img
              :src="'/img/example-h5/page-two-phone-right.png' | cdn"
              alt=""
              class="phone"
            />
          </div>
          <div class="right-content">
            <div class="main-title">自如寓邀你打call</div>
            <div class="sub-title page-two-sub-title">——品牌H5</div>
            <div class="content-text page-two-describe">
              以 H5 的形式在微信体系中传播推广自如新推出的公寓。
            </div>
            <div class="page-two-qrcode">
              <img :src="'/img/example-h5/page-two-qrcode.jpg' | cdn" alt="" />
              <span class="content-text">扫码体验</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-three" :style="`background-image:url(${bg3})`" ref="page3">
      <div class="page-bg-mask">
        <div class="page-content" :class="page3AniShow ? `show-content` : ``">
          <div class="left-content">
            <div class="main-title page-three-title">H5 游戏</div>
            <div class="sub-title page-three-sub-title">——游戏开发</div>
            <div class="content-text page-three-content">
              一款基于网络热梗的投掷类 H5 小游戏。可实现排行<br />榜、微信分享、海报生成等营销功能。
            </div>
            <div class="content-text">
              项目功能：品牌推广<br />技术特点：快速开发迭代，丰富的交互动效和手感，自<br />研基于
              DOM 和 MVVM 的游戏碰撞检测。
            </div>
            <div class="page-three-qrcode">
              <img
                :src="'/img/example-h5/page-three-qrcode.jpg' | cdn"
                alt=""
              />
              <span class="content-text">扫码体验</span>
            </div>
          </div>
          <div class="page-right-phones">
            <img
              :src="'/img/example-h5/page-three-phone-left.png' | cdn"
              alt=""
              class="phone"
            />
            <img
              :src="'/img/example-h5/page-three-phone-right.png' | cdn"
              alt=""
              class="phone phone-right"
            />
          </div>
        </div>
      </div>
    </div>
    <!--    魔豆-->
    <div class="page-four" :style="`background-image:url(${bg4})`" ref="page4">
      <!--      <div class="m-inner-box">-->
      <div class="page-bg-mask">
        <div class="page-content" :class="page4AniShow ? `show-content` : ``">
          <img
            :src="'/img/example-h5/page-four-phone.png' | cdn"
            alt=""
            class="page-four-phone"
          />
          <div class="page-four-right-content">
            <div class="main-title">英语教学游戏</div>
            <div class="sub-title page-four-sub-title">——游戏开发</div>
            <div class="content-text page-four-content">
              易魔豆——为教育机构、培训学校、幼儿园等提供金<br />球优质教育资源和教育技术支持的服务平台；提供针<br />对课程体系的教学、教研、培训、测评等。
            </div>
            <div class="content-text">
              合作方：贝果英语<br />技术特点：可以打包构建到web，h5，ios，<br />android，微信小游戏等各端平台运行，易于使用、<br />高效、灵活
            </div>
          </div>
        </div>
      </div>
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import { scrollTo } from "@/utils";

var debounce = require("lodash.debounce");

export default {
  name: "example-h5",
  data() {
    return {
      bg1: this.$cdn("/img/example-h5/page-one-bg.jpg"),
      bg2: this.$cdn("/img/example-h5/page-two-bg.jpg"),
      bg3: this.$cdn("/img/example-h5/page-three-bg.jpg"),
      bg4: this.$cdn("/img/example-h5/page-four-bg.jpg"),
      page1AniShow: false,
      page2AniShow: false,
      page3AniShow: false,
      page4AniShow: false,
    };
  },
  mounted() {
    document.title = `同袍科技-移动H5`;
    this.page1AniShow = true;
  },
  destroyed() {
    window.removeEventListener('scroll', this.handelWheel)
  },
  created() {
    this.handelWheel = window.addEventListener(
      "scroll",
      this.handleScroll,
      true
    );
  },
  methods: {
    handleScroll(e) {
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 450) {
        this.page2AniShow = true;
      }
      if (scrollTop > 1200) {
        this.page3AniShow = true;
      }
      if (scrollTop > 2000) {
        this.page4AniShow = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/var";

@mixin bgImg {
  width: 100vw;
  min-height: 835px;
  //height: 40vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.page-h5 {
  width: 100%;
  @keyframes contentIn {
    from {
      transform: translateY(50%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .show-content {
    animation: contentIn 2s ease-in-out 0s 1 alternate forwards;
  }

  .page-bg-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.2);
    position: relative;
    z-index: 9;
  }

  .page-content {
    @include center;
    position: relative;
    padding: 120px 0 130px 0;
    opacity: 0;
  }

  .main-title {
    font-size: 64px;
    font-weight: 600;
    color: #ffffff;
    line-height: 90px;
    text-shadow: 2px 3px 4px rgba(63, 61, 58, 0.65);
    font-family: "siyuanBold", sans-serif;
  }

  .sub-title {
    font-size: 48px;
    font-weight: 400;
    color: #ffffff;
    line-height: 67px;
    text-shadow: 2px 3px 4px rgba(63, 61, 58, 0.65);
    font-family: "siyuanBold", sans-serif;
  }

  .content-text {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
  }

  .phone {
    width: 360px;
    height: 721px;
    transform: scale(0.88) translateY(-6%);
  }

  .left-content {
    display: inline-block;
  }

  .page-right-phones {
    display: inline-block;
    float: right;

    .phone-right {
      margin-left: 0px;
    }
  }

  .page-left-phones {
    display: inline-block;

    .phone-left {
      margin-right: 0px;
    }
  }

  .page-one {
    @include bgImg;

    .page-one-sub-title {
      padding-left: 124px;
    }

    .page-one-content {
      max-width: 595px - 30px;
      margin-top: 36px;
      margin-bottom: 65px;
    }

    .page-one-qrcode {
      padding-top: 52px;

      img {
        width: 112px;
        height: 112px;
        margin-right: 35px;
        cursor: pointer;
      }
    }
    .page-one-qrcode{
      img:hover{
        transform: scale(1.5);
        transition: all ease-in 0.3s;
      }
    }
  }

  .page-two {
    @include bgImg;

    .right-content {
      max-width: 600px - 30px;
      float: right;
      margin-top: 0px;

      .page-two-sub-title {
        margin-left: 300px;
      }

      .page-two-describe {
        margin-top: 60px;
      }

      .page-two-qrcode {
        margin-top: 229px;

        img {
          width: 118px;
          height: 118px;
          margin-right: 35px;
          cursor: pointer;
        }
      }
      .page-two-qrcode{
        img:hover{
          transform: scale(1.5);
          transition: all ease-in 0.3s;
        }
      }
    }
  }

  .page-three {
    @include bgImg;

    .page-three-title {
      margin-top: 0px;
    }

    .page-three-sub-title {
      margin-left: 124px;
    }

    .page-three-content {
      margin-top: 36px;
      margin-bottom: 36px;
    }

    .page-three-qrcode {
      margin-top: 52px;
      padding-bottom: 97px;

      img {
        width: 118px;
        height: 118px;
        margin-right: 35px;
        cursor: pointer;
      }
    }
    .page-three-qrcode{
      img:hover{
        transform: scale(1.5);
        transition: all ease-in 0.3s;
      }
    }
  }

  .page-four {
    position: relative;
    @include bgImg;

    .m-inner-box {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
    }

    .page-four-phone {
      width: 876px;
      height: 620px;
      transform: scale(0.8) translateX(-10%);
      //margin-top: 118px;
    }

    .page-four-right-content {
      min-width: 557px;
      position: absolute;
      right: 0;
      top: 196px;

      .page-four-sub-title {
        margin-left: 269px;
      }

      .page-four-content {
        margin-top: 58px;
        margin-bottom: 36px;
      }
    }
  }
}
</style>
