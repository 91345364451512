<template>
	<div class="home-v1-example" :style="`background-image:url(${$cdn(bg)})`">
		<div class="bg"></div>
		<div class="m-inner-content" @click="toPage">
			<div class="m-title">
				{{title}}
			</div>
			<div class="m-sub-title">
				{{subTitle}}
			</div>
			<div class="m-link-button">查看详情</div>
<!--			<router-link class="m-link-button" :to="{name:link}">查看详情</router-link>-->
		</div>
	</div>
</template>
<script>
    export default {
        name: "home-v1-example",
        props: {
            title: String,
            subTitle: String,
            bg: String,
            link: String,
        },
      methods:{
        toPage(){
          this.$router.push({name:this.link})
        }
      }
    }
</script>
<style lang="scss" scoped>
	.home-v1-example {
		position: relative;
		flex: 1;
		height: 770px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.bg {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		//opacity: 0.85;
		transition: all ease .3s;
    background: linear-gradient(180deg, rgba(27, 25, 23, 0.9) 0%, rgba(39, 34, 29, 0.6) 100%);
	}
	.m-inner-content {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
	.m-title {
		margin: 59px 0 0 49px;
		//height: 50px;
		font-size: 36px;
		font-weight: 600;
		color: #FAF8F5;
		line-height: 50px;
		letter-spacing: 5px;
    font-family: "siyuanBold", sans-serif;
	}
	.m-sub-title {
		margin: 1px 0 0 49px;
		font-size: 20px;
		font-weight: 400;
		color: #FAF8F5;
		line-height: 28px;
		letter-spacing: 1.5px;
	}
	.m-link-button {
		display: block;
		position: absolute;
		left: 47px;
		bottom: 57px;
		width: 153px;
		height: 50px;
		background: #CAAB78;
		border-radius: 25px;
		line-height: 50px;
		font-size: 20px;
		font-weight: 500;
		text-align: center;
		color: #fff;
		text-decoration: none;
		transition: all ease .3s;
		&:active,&:visited,&:link {
			color: #fff;
			text-decoration: none;
			letter-spacing: 1px;
			text-indent: 1px;
		}
		&:hover {
			background: #9e8357;
		}
	}

	.home-v1-example:hover {
    .m-title{
      display: none;
    }
    .m-sub-title{
      display: none;
    }
		.bg {
			opacity: 0;
		}
	}

</style>
