import Vue from 'vue';

import VueRouter from 'vue-router';

import HomeV1 from '@/pages/home-v1';
import HomeV2 from '@/pages/home-v2';

import ExampleApp from "../pages/example-app"
import ExampleVrCity from "../pages/example-vr-city"
import ExamplePI from  "../pages/example-pi"
import ExampleIndex from "../pages/example-index"
import ExampleH5 from  "../pages/example-h5"
Vue.use(VueRouter);



const examples = [
  {
    path: "/example/app",
    name: "example-app",
    meta: {
      nav: "example-app",
    },
    component: ExampleApp,
  },
  {
    path: "/example/h5",
    name: "example-h5",
    meta: {
      nav: "example-h5",
    },
    component: ExampleH5,
  },
  {
    path: "/example/index",
    name: "example-index",
    meta: {
      nav: "example-index",
    },
    component: ExampleIndex,
  },
  {
    path: "/example/pi",
    name: "example-pi",
    meta: {
      nav: "example-pi",
    },
    component: ExamplePI,
  },
  {
    path: "/example/vr-city",
    name: "example-vr-city",
    meta: {
      nav: "example-vr-city",
    },
    component: ExampleVrCity,
  },
];

const routes = [
  {
    path: "/",
    name: "home-v1",
    component: HomeV1,
    meta: {
      nav: "home-v1",
    },
  },
  ...examples
];

const router = new VueRouter({
  routes,
});

export default router;
