<template>
  <div class="page-app">
    <div class="m-page1" :style="`background-image:url(${bg1})`">
      <div class="m-content" :class="page1AniShow ? `show-content` : ``">
        <img :src="'/img/example-app/page1-phone.png'|cdn" alt="" class="left-phone">
        <div class="page1-right-content">
          <div class="company-name">
            <img :src="'/img/example-app/logo.png'|cdn" alt="">
            <span class="name">同袍商城</span>
          </div>
          <img :src="'/img/example-app/page1-content.png'|cdn" alt="" class="page1-content">
          <div class="page1-icons">
            <img :src="'/img/example-app/app-store.png'|cdn" alt="" class="icon-app-store">
            <img :src="'/img/example-app/android.png'|cdn" alt="" class="icon-android">
            <img :src="'/img/example-app/page1-qrcode.png'|cdn" alt="" class="icon-qrcode">
          </div>
        </div>
      </div>
    </div>
    <div class="m-page2" :style="`background-image:url(${bg2})`">
      <div class="m-content" :class="page2AniShow ? `show-content` : ``">
        <div class="page2-left-content">
          <div class="shop-title">多分类</div>
          <div class="shop-title-line"></div>
          <div class="shop-content">亿万商品任你选，一键下单轻松购<br/>
            精品热门排行榜，天天促销有折扣
          </div>
        </div>
        <div class="page2-right-phone">
          <img :src="'/img/example-app/page2-phone.png'|cdn" alt="">
        </div>
      </div>
    </div>
    <div class="m-page3" :style="`background-image:url(${bg3})`">
      <div class="m-content" :class="page3AniShow ? `show-content` : ``">
        <div class="page3-left-phone">
          <img :src="'/img/example-app/page3-phone.png'|cdn" alt="">
        </div>
        <div class="page3-right-content">
          <div class="shop-title">易搜索</div>
          <div class="shop-title-line"></div>
          <div class="shop-content">精准搜索找商品，地图跟踪查订单<br/>
            降价通知及时晓，送货时间由你定
          </div>
        </div>
      </div>
    </div>
    <div class="m-page4">
      <div :style="`background-image:url(${bg4Circle})`" class="page4-circle"></div>
      <div class="m-content" :class="page4AniShow ? `show-content` : ``">
        <img :src="'/img/example-app/page4-phone.png'|cdn" alt="" class="page4-phone">
        <div class="right-content-wrap">
          <div class="blue-circle"></div>
          <div class="title-common-text chamber-title">中国国际商会</div>
          <div class="title-common-text chamber-sub-title">小程序</div>
          <div class="chamber-content">
            中国国际商会（CCOIC）是1988年经国务院批准成立、由在中国从事国际商事活动的企业、团体和其他组织组成的全国性商会组织，
            是代表中国参与国际商会（ICC）工作的国家商会，在开展与国际商会有关业务时使用国际商会中国国家委员会（ICC
            China）名称，业务主管单位是中国国际贸易促进委员会。
          </div>
        </div>
      </div>
    </div>
    <div class="m-page5">
      <div class="page5-circle" :style="`background-image:url(${bg5Circle})`"></div>
      <div class="m-last-content">
        <div class="left-cards">
          <div class="card-common card1" :class="page5AniShow ? `card1-show` : ``">
            <img :src="'/img/example-app/icon1-blue.png'|cdn" alt="" class="card1-icon">
            <img :src="'/img/example-app/icon1-white.png'|cdn" alt="" class="card1-icon-white">
            <span class="card1-text">合作方：中国国际商会</span>
          </div>
          <div class="card-common card2" :class="page5AniShow ? `card2-show` : ``">
            <img :src="'/img/example-app/icon2-blue.png'|cdn" alt="" class="card2-icon-common card2-icon">
            <img :src="'/img/example-app/icon2-white.png'|cdn" alt="" class="card2-icon-common card2-icon-white">
            <span class="card2-text">项目功能：活动宣传、项目合作</span>
          </div>
          <div class="card-common card3" :class="page5AniShow ? `card3-show` : ``">
            <img :src="'/img/example-app/icon3-blue.png'|cdn" alt="" class="card3-icon-common card3-icon">
            <img :src="'/img/example-app/icon3-white.png'|cdn" alt="" class="card3-icon-common card3-icon-white">
            <div class="card3-text">技术特色：</div>
            <div class="card3-content">多页面富文本展示，<br/>
              多种活动关联形式
            </div>
          </div>
        </div>
        <div class="right-img" :class="page5AniShow ? `show-content` : ``">
          <img :src="'/img/example-app/chamber-calendar.png'|cdn" alt="" class="img-calendar">
          <img :src="'/img/example-app/chamber-mine.png'|cdn" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var debounce = require("lodash.debounce");
export default {
  name: 'example-app',
  mounted() {
    document.title = `同袍科技-APP/小程序`;
    this.page1AniShow = true
  },
  data() {
    return {
      bg1: this.$cdn("/img/example-app/bg1.png"),
      bg2: this.$cdn("/img/example-app/bg2.png"),
      bg3: this.$cdn("/img/example-app/bg3.png"),
      bg4Circle: this.$cdn("/img/example-app/page4-circle.png"),
      bg5Circle: this.$cdn("/img/example-app/page5-circle.png"),
      page1AniShow: false,
      page2AniShow: false,
      page3AniShow: false,
      page4AniShow: false,
      page5AniShow: false,
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handelWheel)
  },
  created() {
    this.handelWheel = window.addEventListener(
        "scroll",
        this.handleScroll,
        true
    );
  },
  methods: {
    handleScroll(e) {
      var scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
      console.log(scrollTop)
      if (scrollTop > 400) {
        this.page2AniShow = true;
      }
      if (scrollTop > 900) {
        this.page3AniShow = true;
      }
      if (scrollTop > 1700) {
        this.page4AniShow = true;
      }
      if(scrollTop > 2100){
        this.page5AniShow = true;
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../style/var";

@mixin bgImg {
  width: 100vw;
  //height: 40vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.page-app {
  width: 100vw;
  @keyframes contentIn {
    from {
      transform: translateY(50%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .show-content {
    animation: contentIn 2s ease-in-out 0s 1 alternate forwards;
  }

  .m-content {
    @include center;
    position: relative;
    opacity: 0;
    //background-color: #9e8357;
  }
  .m-last-content{
    @include center;
    position: relative;
  }
  .page-content-show{
    opacity: 1;
  }
  .shop-title {
    font-size: 48px;
    font-weight: 500;
    color: #333333;
    line-height: 67px;
    letter-spacing: 6px;
    font-family: "siyuanBold", sans-serif;
  }

  .shop-title-line {
    width: 70px;
    height: 5px;
    background: #F8C120;
    margin-top: 10px;
    margin-bottom: 55px;
  }

  .shop-content {
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    letter-spacing: 3px;
  }

  .m-page1 {
    @include bgImg;

    .left-phone {
      width: 341px;
      height: 663px;
      margin: 99px 0 99px 130px;
    }

    .page1-right-content {
      position: absolute;
      top: 155px;
      right: 100px;

      .company-name {
        position: relative;

        img {
          width: 55px;
          height: 55px;
          display: inline-block;
          vertical-align: middle;
        }

        .name {
          font-size: 36px;
          font-weight: 400;
          color: #333333;
          line-height: 55px;
          vertical-align: middle;
          margin-left: 25px;
          display: inline-block;
        }
      }

      .page1-content {
        width: 465px;
        height: 290px;
        margin-top: 40px;
      }

      .page1-icons {
        margin-top: 79px;
        height: 58px;

        .icon-app-store {
          width: 193px;
          height: 58px;
          display: inline-block;
        }

        .icon-android {
          width: 193px;
          height: 58px;
          margin-left: 35px;
          display: inline-block;
        }

        .icon-qrcode {
          width: 32px;
          height: 30px;
          margin-left: 35px;
          display: inline-block;
          vertical-align: top;
          margin-top: 14px;
        }
      }
    }
  }

  .m-page2 {
    @include bgImg;
    height: 860px;

    .page2-left-content {
      margin-left: 137px;
      padding-top: 213px;
    }

    .page2-right-phone {
      position: absolute;
      top: 95px;
      right: 180px;

      img {
        width: 351px;
        height: 683px;
      }
    }
  }

  .m-page3 {
    @include bgImg;
    height: 860px;

    .page3-left-phone {
      padding-top: 95px;
      padding-left: 144px;

      img {
        width: 351px;
        height: 683px;
      }
    }

    .page3-right-content {
      position: absolute;
      top: 214px;
      right: 183px;
    }
  }

  .m-page4 {
    width: 100vw;
    height: 841px;
    position: relative;

    .page4-circle {
      width: 859px;
      height: 758px;
      transform: scale(0.9) translateX(-5.5%) translateY(-5.5%);
      background-position: center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    .page4-phone {
      width: 1172px;
      height: 586px;
      transform: scale(0.8) translateX(-25%);
      margin-top: 224px;
    }

    .right-content-wrap {
      position: absolute;
      top: 143px;
      right: 0;
      text-align: right;

      .blue-circle {
        width: 102px;
        height: 102px;
        background: #409BFF;
        border-radius: 51px;
        position: absolute;
        right: 0;
        top: 0;
      }

      .title-common-text {
        font-size: 64px;
        font-weight: 600;
        line-height: 90px;
        position: relative;
        z-index: 9;
        margin-right: 40px;
        font-family: "siyuanBold", sans-serif;
      }

      .chamber-title {
        color: #3D3D3D;
        margin-top: 50px;
      }

      .chamber-sub-title {
        color: #409BFF;
      }

      .chamber-content {
        text-align: left;
        width: 470px;
        font-size: 20px;
        font-weight: 400;
        color: #282828;
        line-height: 35px;
        margin-top: 29px;
        margin-right: 40px;
      }
    }
  }

  .m-page5 {
    width: 100vw;
    height: 935px;
    position: relative;
    @keyframes cardShow {
      from {
        transform: translateY(50%);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
    .card1-show{
      animation: cardShow 0.5s ease-in-out 0.5s 1 alternate forwards;
    }
    .card2-show{
      animation: cardShow 0.5s ease-in-out 1s 1 alternate forwards;
    }
    .card3-show{
      animation: cardShow 0.5s ease-in-out 1.5s 1 alternate forwards;

    }
    .page5-circle {
      width: 824px;
      height: 525px;
      background-position: center;
      background-size: cover;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .left-cards {
      padding-left: 35px;
      padding-top: 202px;

      .card-common {
        width: 530px;
        height: 133px;
        background: #FFFFFF;
        box-shadow: 0px 4px 30px 0px rgba(35, 51, 73, 0.2);
        font-size: 24px;
        color: #282828;
        line-height: 40px;
        position: relative;
      }

      .card1 {
        opacity: 0;
        .card1-icon {
          width: 54px;
          height: 38px;
          margin-top: 48px;
          margin-left: 23px;
        }

        .card1-icon-white {
          width: 54px;
          height: 38px;
          margin-top: 48px;
          margin-left: 23px;
          display: none;
        }

        .card1-text {
          position: absolute;
          top: 44px;
          left: 100px;
        }
      }

      .card1:hover {
        background: #409BFF;
        color: #FFFFFF;

        .card1-icon {
          display: none;
        }

        .card1-icon-white {
          display: inline-block;
        }
      }

      .card2 {
        margin-top: 45px;
        opacity: 0;
        .card2-icon-common {
          width: 48px;
          height: 55px;
          margin-top: 38px;
          margin-left: 25px;
        }

        .card2-icon-white {
          display: none;
        }

        .card2-text {
          position: absolute;
          top: 48px;
          left: 100px;
        }
      }

      .card2:hover {
        background: #409BFF;
        color: #FFFFFF;

        .card2-icon {
          display: none;
        }

        .card2-icon-white {
          display: inline-block;
        }
      }

      .card3 {
        margin-top: 45px;
        opacity: 0;
        .card3-icon-common {
          width: 42px;
          height: 54px;
          margin-top: 39px;
          margin-left: 24px;
        }

        .card3-icon-white {
          display: none;
        }

        .card3-text {
          position: absolute;
          top: 45px;
          left: 100px;
        }

        .card3-content {
          position: absolute;
          top: 23px;
          left: 225px;
        }
      }

      .card3:hover {
        background: #409BFF;
        color: #FFFFFF;

        .card3-icon {
          display: none;
        }

        .card3-icon-white {
          display: inline-block;
        }
      }
    }

    .right-img {
      position: absolute;
      top: 0;
      right: 0;

      img {
        width: 475px;
        height: 912px;
        transform: scale(0.9);
      }

      .img-calendar {
        position: absolute;
        top: 0;
        right: 188px;
        z-index: 9;
      }
    }
  }
}
</style>
