<template>
  <div class="page-example-pi">
    <!--		banner区-->
    <div class="banner">
      <img :src="'/img/example-pi/banner.jpg'|cdn" alt="全景直播"/>
    </div>
    <!--		intro-->
    <div class="intro">
      <div class="circle-right"></div>
      <div>
        <!--				介绍标题-->
        <div class="m-title-1">
          跨平台、多终端、高实时性、全景音视频5G沟通解决方案
        </div>
        <!--				features-->
        <example-pi-features/>
      </div>
    </div>
    <!--		应用场景-->
    <div class="m-application">
      <div class="m-application-title"></div>
      <div class="m-application-items-wrap">
        <div class="m-item item-1">
          <img :src="'/img/example-pi/application-scene-1.jpg'|cdn" alt="">
          <div class="text">工地踏勘<br>现场验收</div>
        </div>
        <div class="m-item item-2">
          <img :src="'/img/example-pi/application-scene-2.jpg'|cdn" alt="">
          <div class="text">林场<br>河道巡视</div>
        </div>
        <div class="m-item item-3">
          <div class="m-img-wrap">
            <img :src="'/img/example-pi/application-scene-3-1.jpg'|cdn" class="m-img-1" alt="">
            <img :src="'/img/example-pi/application-scene-3-2.png'|cdn" class="m-img-2" alt="">
          </div>
          <div class="text">园区安保<br>路况直播</div>
        </div>
      </div>

      <!--			服务对象-->
      <div class="m-service-customers">
        <div class="title-2"></div>
        <div class="customer-item-wrap">
          <div class="item">
            <img :src="'/img/example-pi/labpano.png'|cdn" class="item-1" alt="圆周率">
          </div>
          <div class="item">
            <img :src="'/img/example-pi/renri.png'|cdn" class="item-2" alt="人民日报">
          </div>
          <div class="item">
            <img :src="'/img/example-pi/cctv.png'|cdn" class="item-3" alt="CCTV">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExamplePiFeatures from "./components/example-pi-features";
import {scrollTo} from "@/utils"

export default {
  components: {ExamplePiFeatures},
  mounted() {
    document.title = `同袍科技-全景直播`;
    scrollTo(0)
  }
}
</script>
<style lang="scss" scoped>
@import "../style/var";

.banner {
  img {
    display: block;
    width: 100vw;
    height: auto;
  }
}

.intro {
  position: relative;
  float: left;
  width: 100vw;
  padding: 60px 0;
  text-align: center;

  .circle-right {
    background-image: url("../assets/icon/example-pi/circle-left.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 0;
    width: 0.5 * 662px;
    height: 0.5 * 462px;
  }

  .m-title-1 {
    height: 52px;
    font-size: 24px;
    font-weight: 400;
    color: #272727;
    line-height: 26px;
  }
}

.m-application {
  margin-top: 25px;
  float: left;
  width: 100vw;
  text-align: center;
}

.m-application-title {
  display: block;
  margin: 0 auto;
  background-image: url("../assets/icon/example-pi/title-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 0.5 * 724px;
  height: 0.5 *148px;
  margin-top: 30px;
}

.m-application-items-wrap {
  display: flex;
  align-items: stretch;
  @include center();
  margin-top: 55px;

  img {
    display: block;
    margin: 0 auto;
  }

  .m-item {
    flex: auto;

    .text {
      margin: 24px auto 0 auto;
      font-size: 20px;
      color: #272727;
      line-height: 32px;
    }
  }

  .item-1 {
    transform: scale(0.8) translateX(-6%);
    img {
      width: 319px;

    }
  }

  .item-2 {
    transform: scale(0.8) translateX(-10%);
    img {
      width: 757px;
    }
  }

  .item-3 {
    transform: scale(0.8) translateX(-42%);
    .m-img-wrap {
      margin: 0 auto;
      position: relative;
      width: 319px;
    }

    .m-img-1 {
      width: 319px;
    }

    .m-img-2 {
      position: absolute;
      width: 165px;
      top: 27px;
      left: 273px;
    }
  }
}

.m-service-customers {
  @include center();
  text-align: center;
  margin-top: 65px;
  margin-bottom: 98px;

  .title-2 {
    background-image: url("../assets/icon/example-pi/title-2.png");
    width: 0.5 * 724px;
    height: 0.5 * 150px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
  }

  .customer-item-wrap {
    display: flex;
    align-items: stretch;
    width: inherit;
    margin-top: 84px;
  }

  .item {
    flex: 1;
  }

  .item-1 {
    width: 380px;
  }

  .item-2 {
    width: 336px;
  }

  .item-3 {
    width: 346px;
  }
}


</style>
