export function resolvePath(path){
    if (!path) return ''
    if (path.indexOf("//") == 0 || path.indexOf("http:") == 0 || path.indexOf("https:") == 0) {
      return path;
    } else {
      return process.env.VUE_APP_CDN_PREFIX + path;
    }
  }
  export function scrollTo(y) {
      // var top = document.documentElement.scrollTop;
      // const step = (y - top) / 15;
      // const func = () => {
      //     if (Math.abs(y - top) < 1) {
      //         return;
      //     } else {
      //         top += step;
      //         document.documentElement.scrollTop = top;
      //         setTimeout(func, 16);
      //     }
      // };
      // func();
  }
