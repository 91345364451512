<template>
  <div class="page-home-v1">
    <!--    banner区-->
    <swiper class="m-banner" :options="swiperOption">
      <swiper-slide v-for="(item,index) in banners" :key="index" class="m-siwper-page">
        <img :src="item.url" alt=""/>
<!--        <div :style="`background-image:url(${item.url})`" class="banner"></div>-->
        <!--        <div class="banner"></div>-->
        <!--          <div v-if="index == 0">-->
        <!--            <img src="../assets/banner-bottom-arrow.png" alt="" class="banner-bottom-arrow">-->
        <!--            <div class="banner-more" @click="toCompanyIntro">了解更多</div>-->
        <!--          </div>-->
      </swiper-slide>
      <div class="swiper-pagination"  slot="pagination"></div>
    </swiper>
    <!--		<div class="m-banner">-->
    <!--			<div class="m-siwper-page" :style="`background-image:url(${banner})`">-->
    <!--				<img :src="banner" alt=""/>-->
    <!--			</div>-->
    <!--		</div>-->
    <!--		介绍区-->
    <div class="m-comp-intro">
      <!--			装饰元素-->
      <div class="circle-1"></div>
      <div class="circle-2"></div>
      <!--			标题-->
      <div class="m-title-1"></div>
      <div class="slogan">用技术妆点人文的灵魂</div>
      <!--			三个icon-->
      <div class="intro">
        <!--				1-->
        <div class="m-intro-item">
          <img src="../assets/icon/home-v1/icon-1.png" alt="技术丰富">
          <div class="feature-zh">技术丰富</div>
          <div class="feature-en">Rich Technology</div>
        </div>
        <!--				2-->
        <div class="m-intro-item">
          <img src="../assets/icon/home-v1/icon-2.png" alt="专人负责">
          <div class="feature-zh">专人负责</div>
          <div class="feature-en">Special Person In Charge</div>
        </div>
        <!--				3-->
        <div class="m-intro-item">
          <img src="../assets/icon/home-v1/icon-3.png" alt="软硬一体">
          <div class="feature-zh">软硬一体</div>
          <div class="feature-en">Integration Of<br>Software And Hardware</div>
        </div>
      </div>
      <!--			标题2-->
      <div class="m-title-2"></div>
    </div>
    <!--			案例-->
    <div class="m-example-wrap">
      <home-v1-example title="全景直播" sub-title="Panoramic live broadcast" link="example-pi"
                       bg="/img/home-v1/example-pano.jpg"/>
      <home-v1-example title="官网开发" sub-title="Official website" link="example-index"
                       bg="/img/home-v1/example-index.jpg"/>
      <home-v1-example title="联合国VR城市" sub-title="United Nations VR city" link="example-vr-city"
                       bg="/img/home-v1/example-vr.jpg"/>
      <home-v1-example title="APP/小程序" sub-title="APP/MiniProgram" link="example-app"
                       bg="/img/home-v1/example-app.jpg"/>
      <home-v1-example title="移动H5" sub-title="Mobile Web H5" link="example-h5" bg="/img/home-v1/example-h5.jpg"/>
    </div>
  </div>
</template>
<script>
import HomeV1Example from "./components/home-v1-example";
import {scrollTo} from "@/utils"

export default {
  components: {HomeV1Example},
  data() {
    return {
      banners: [{url: this.$cdn('/img/home-v1/banner1.jpg')},
        {url: this.$cdn('/img/home-v1/banner2.jpg')},
        {url: this.$cdn('/img/home-v1/banner3.jpg')},
        {url: this.$cdn('/img/home-v1/banner4.jpg')},
      ],
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        loop: true,
        autoplay: true
      },
    }
  },
  mounted() {
    document.title = `同袍科技-首页`;
    scrollTo(0)
  }
}
</script>
<style lang="scss">
@import "../style/var";

.m-banner {
  display: block;
}
.swiper-pagination-bullet{
  width: 8px;
  height: 8px;
  background: #2B3F7E;
  z-index: 99;
}
.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
  bottom: 30px;
}
.swiper-pagination-bullet-active{
  width: 36px;
  height: 8px;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px 0px rgba(135, 135, 135, 0.5);
  border-radius: 8px;

}
.m-siwper-page {
  width: 100vw;
  //.banner{
  //  width: 100vw;
  //  height: 833px;
  //  background-position: center;
  //  background-size: cover;
  //}
  img {
    width: 100vw;
  }
}

.m-comp-intro {
  height: 844px;
  position: relative;
  float: left;
  overflow: hidden;
  width: 100vw;
  text-align: center;

  .circle-1 {
    position: absolute;
    top: 0;
    right: 0;
    background-image: url("../assets/icon/home-v1/circle-left.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 0.5 * 662px;
    height: 0.5 * 462px;
  }

  .circle-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url("../assets/icon/home-v1/circle-right.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 0.5 * 698px;
    height: 0.5 * 606px;
  }

  .m-title-1 {
    margin: 90px auto 0 auto;
    background-image: url("../assets/icon/home-v1/title1.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 0.5 * 724px;
    height: 0.5 * 152px;
  }

  .slogan {
    margin: 24px auto 0 auto;
    height: 36px;
    font-size: 24px;
    font-weight: 400;
    color: #2C2C2C;
    line-height: 36px;
    letter-spacing: 2px;
  }

  .intro {
    display: flex;
    align-items: stretch;
    @include center();
    margin-top: 103px;
  }

  .m-intro-item {
    flex: 1;

    img {
      width: 87px;
      height: 100px;
    }

    .feature-zh {
      margin: 30px auto 0 auto;
      height: 33px;
      font-size: 24px;
      font-weight: 500;
      color: #2C2C2C;
      line-height: 33px;
    }

    .feature-en {
      margin: 5px auto 0 auto;
      font-size: 24px;
      font-weight: 500;
      color: #A4A4A4;
      line-height: 33px;
    }
  }

  .m-title-2 {
    margin: 131px auto 0 auto;
    background-image: url("../assets/icon/home-v1/title2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 0.5 * 724px;
    height: 0.5 * 152px;
  }
}

.m-example-wrap {
  display: flex;
  align-items: stretch;
  width: 100vw;
  cursor: pointer;
}
</style>
