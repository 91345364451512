<template>
  <div class="page-example-index">
    <div class="top-index" :style="`background-image:url(${bg1})`">
      <div class="content" :class="page1AniShow ? `show-content` : ``">
        <div class="title-text">格子笔记 —— 官网</div>
        <div class="content-wrap-text content-wrap">
          合作方：格子笔记<br />项目功能：产品展示<br />技术特点：静态页面
        </div>
        <div class="qrcode-wrap">
          <img
            :src="'/img/example-index/notes-qrcode.png' | cdn"
            alt=""
            class="notes-qrcode"
          />
          <span class="qrcode-tips content-wrap-text">移动端扫码体验</span>
        </div>
        <div class="content-wrap-text">
          电脑端网址：<a href="http://note.sumi.io">http://note.sumi.io</a>
        </div>
        <div class="right-img-wrap">
          <img
            :src="'/img/example-index/notes-pc.png' | cdn"
            alt=""
            class="notes-pc"
          />
          <img
            :src="'/img/example-index/notes-phone.png' | cdn"
            alt=""
            class="notes-phone"
          />
        </div>
      </div>
    </div>
    <div class="bottom-index" :style="`background-image:url(${bg2})`">
      <div class="bottom-content" :class="page2AniShow ? `show-content` : ``">
        <img
          :src="'/img/example-index/nuobaiai-pc.png' | cdn"
          alt=""
          class="content-img-pc"
        />
        <div class="right-content-wrap">
          <div class="title-text">诺百爱（杭州）科技</div>
          <div class="title-text text-right">——官网</div>
          <div class="content-wrap-text">
            诺百爱（杭州）科技有限责任公司是国内首创的生物电信号领域人工智能头部企业，提供生物电信号的AI解决方案，包括肌电、脑电、心电等识别及应用。核心产品包括国内第一款手语翻译臂环、新型的肌电交互臂环，可作为AR/VR的“鼠标键盘”等，还研发了首款帮助聋人接打电话的辅助通话设备“龙语”；成立一年多，已经承担军委科技委、装备发展部等单位重要合作项目。
          </div>
          <div class="content-wrap-text pc-links">
            电脑端网址：<a href="https://nuobaiai.tpstu.com/#/"
              >https://nuobaiai.tpstu.com/#/</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { scrollTo } from "@/utils";

export default {
  name: "example-index",
  data() {
    return {
      bg1: this.$cdn("/img/example-index/page-one-bg.png"),
      bg2: this.$cdn("/img/example-index/page-two-bg.png"),
      page1AniShow: false,
      page2AniShow: false,
    };
  },
  mounted() {
    document.title = `同袍科技-官网开发`;
    this.page1AniShow = true;
  },
  destroyed() {
    window.removeEventListener('scroll', this.handelWheel)
  },
  created() {
    this.handelWheel = window.addEventListener(
      "scroll",
      this.handleScroll,
      true
    );
  },
  methods: {
    handleScroll(e) {
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 250) {
        this.page2AniShow = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/var";

.page-example-index {
  width: 100%;
  @keyframes contentIn {
    from {
      transform: translateY(50%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .show-content {
    animation: contentIn 2s ease-in-out 0s 1 alternate forwards;
  }

  .title-text {
    font-size: 64px;
    font-weight: 600;
    color: #ffffff;
    line-height: 90px;
    text-shadow: 2px 3px 4px rgba(63, 61, 58, 0.65);
    font-family: "siyuanBold", sans-serif;
  }

  .content-wrap-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 33px;
    color: #ffffff;
    a {
      color: #ffffff;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .top-index {
    width: 100%;
    //height: 836px;
    background-size: cover;
    background-position: center;
    position: relative;

    .content {
      @include center;
      padding-top: 169px;
      padding-bottom: 159px;
      position: relative;
      opacity: 0;

      .content-wrap {
        padding-top: 66px;
      }

      .qrcode-wrap {
        margin-top: 77px;
        margin-bottom: 15px;

        .notes-qrcode {
          width: 117px;
          height: 117px;
          cursor: pointer;
        }
        .notes-qrcode:hover{
          transform: scale(1.5);
          transition: all ease-in 0.3s;
        }

        .qrcode-tips {
          padding-left: 35px;
        }
      }

      .right-img-wrap {
        position: absolute;
        right: 0;
        top: 139px;

        .notes-pc {
          width: 811px;
          height: 563px;
          position: absolute;
          right: 67px;
          top: 0;
          transform: scale(0.8) translateX(15%);
        }

        .notes-phone {
          width: 234px;
          height: 471px;
          position: absolute;
          right: 0;
          top: 135px;
          transform: scale(0.8) translateX(14%);
        }
      }
    }
  }

  .bottom-index {
    width: 100%;
    height: 923px;
    background-size: cover;
    background-position: center;
    position: relative;

    .bottom-content {
      @include center;
      padding-top: 222px;
      position: relative;
      opacity: 0;

      .content-img-pc {
        width: 811px;
        height: 563px;
        transform: scale(0.8) translateY(-10%) translateX(-12%);
      }

      .right-content-wrap {
        width: 595px;
        position: absolute;
        right: 0;
        top: 217px;

        .text-right {
          text-align: right;
          margin-top: 21px;
          margin-bottom: 34px;
        }

        .pc-links {
          margin-top: 43px;
        }
      }
    }
  }
}
</style>
